import {Component, Vue} from "vue-property-decorator"

@Component
export default class IframeHolderMixin extends Vue {

    parentScrolled(iframe: HTMLIFrameElement) {
        const documentHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.scrollY;
        const clientHeight = window.innerHeight;
        if (iframe.contentWindow) {
            iframe.contentWindow.postMessage(
                {
                    type: "parentScrolled",
                    documentHeight,
                    scrollPosition,
                    clientHeight,
                },
                "*"
            );
        }
    }

}
